import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import cn from 'classnames'
import './services_steps.sass'
import {Link} from "gatsby";
import {media} from "../styles/MediaMixins";
import Container from "../layouts/Container";
import Row from "../layouts/Row";
import OrderedRowItem from "../layouts/OrderedRowItem";
import RowItem from "../layouts/RowItem";

class Canvas extends React.Component {

  draw_curve(ctx) {
// #v1
    ctx.save();
    ctx.transform(1.000000, 0.000000, 0.000000, 1.000000, -339.000000, -1561.000000);

// #Path-2
    ctx.lineWidth = 6;
    ctx.strokeStyle = "#97979733";
    ctx.setLineDash([8, 10]);
    ctx.beginPath();
    ctx.moveTo(341.000000, 1564.000000);
    ctx.bezierCurveTo(395.309987, 1589.782160, 559.240143, 1644.540080, 878.861204, 1644.540080);
    ctx.bezierCurveTo(1087.024420, 1644.540080, 1451.120380, 1593.873840, 1611.000000, 1708.000000);
    ctx.stroke();
    ctx.restore();
  }

  componentDidMount() {
    this.updateCanvas();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateCanvas();
  }

  updateCanvas() {
    const ctx = this.refs.canvas.getContext('2d');
    ctx.clearRect(0, 0, 300, 300);
    // draw children “components”
    this.draw_curve(ctx);
  }

  render() {
    return (
      <canvas ref={"canvas"} width={this.props.width} height={146}/>
    )
  }
}

const Title = styled.h2`
    ${media.touch`
        font-size: ${36 / 16}rem;
        line-height: ${36 / 16}rem;
        margin-top: ${36 / 16}rem;
        margin-bottom: ${12 / 16}rem;
    `};
    ${media.desktop`
        font-size: 3rem;
        line-height: ${61 / 16}rem;
        text-align: ${props => props.textAlign || 'unset'};
        margin-${props => props.textAlign}: ${40 / 16}rem;
        margin-${props => props.textAlign}: ${40 / 16}rem;
    `};
    font-weight: 700;
    width: 100%;
    color: #535B6C;
    margin-bottom: ${21 / 16}rem;
`

const Content = styled.div`
    font-size: 1.125rem;
    line-height: 2rem;
    ${media.desktop`
        text-align: ${props => props.text_align || 'unset'};
        margin-${props => props.text_align}: 2.5rem;
    `};
    max-width: 38.375rem;
    padding-bottom: 0.625rem;
    font-weight: 500;
    color: #535B6C66;
    width: 100%;
`

const ServiceRow = styled(Row)`
    ${media.desktop`
        justify-content: ${props => props.alightItems};
    `}
    ${media.touch`
        justify-content: center;
        flex-direction: column;
    `}
`

const ServiceAction = styled(Link)`
    color: #20BB87;
    ${media.touch`
        font-size: ${20 / 16}rem;
        line-height: ${25 / 16}rem
    `};
    ${media.desktop`
        font-size: ${20 / 16}rem;
        line-height: ${25 / 16}rem;
    `};
    font-weight: bold;
    text-decoration: underline;
    margin-top: ${40 / 16}rem;
`

const ImageCircle = styled.div`
    border-radius: 50%;
    @media (max-width: ${1023 / 16}rem) {
        width: 100%;
        height: 100%;
    }
    @media (min-width: ${1024 / 16}rem) {
        width: ${321 / 16}rem;
        height: ${321 / 16}rem;
    }
    display: flex;
    background-color: #FFFFFF;
    box-shadow: 0 2px 26px 0 rgba(24,224,142,0.3);
    margin-right: auto;
    margin-left: auto;
    img {
        margin: auto;
        overflow:hidden
    }
`

const ServiceRowItem = styled(OrderedRowItem)`
    display: flex;
    ${media.touch`
        margin-top: 2rem;
        max-height: ${321 / 16}rem;
        width: calc(80vw - 3.125rem);
        height: calc(80vw - 3.125rem);
    `};
`

const ServiceRowItemSteps = styled(OrderedRowItem)`
    display: flex;
    flex-direction: column;
    ${media.desktop`
        ${props => props.flex_align && `align-items: flex-${props.flex_align}`};
    `};
    ${media.touch`
        align-items: flex-start;
    `};
`

const ServiceStep = ({title, subtitle, action, image, right}) => {
  return (
    <Container>
      <ServiceRow alightItems={right ? 'flex-start' : 'flex-end'}>
        <ServiceRowItem style={{zIndex: 2}} maxWidth={321} shortMaxWidth={321}
                        smOrder={1} lgOrder={right ? 1 : 2}>
          <ImageCircle>
            <img src={image} alt={'service icon'}/>
          </ImageCircle>
        </ServiceRowItem>
        <ServiceRowItemSteps flex_align={!right ? 'end' : 'start'} maxWidth={860} smOrder={2} lgOrder={right ? 2 : 1}>
          <Title textAlign={!right ? 'right' : 'left'}>
            {title}
          </Title>
          <Content text_align={!right ? 'right' : 'left'}>
            <p>{subtitle}</p>
            {action &&
            <ServiceAction text_align={!right ? 'right' : 'left'} to={`/services/${action}/`}>
              LEARN MORE
            </ServiceAction>}
          </Content>

        </ServiceRowItemSteps>
      </ServiceRow>
    </Container>
  );
};

ServiceStep.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired
};

const CurveContainer = styled(Container)`
    padding-left: 0;
    padding-right: 0;
    ${media.touch`display: none`};
`

const CurveRowItem = styled(RowItem)`
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-${props => props.index % 2 === 0 ? 'left' : 'right'}: ${321 / 2 - 30}px;
    padding-${props => props.index % 2 === 0 ? 'right' : 'left'}: ${321 / 2 + 60}px;
    margin-${props => props.index % 2 === 0 ? 'top' : 'bottom'}: -12px;
    margin-${props => props.index % 2 === 0 ? 'bottom' : 'top'}: -12px;
    canvas {
        width: 100%;
    }
`

const ServiceSteps = ({steps}) => {
  const stepContent = [];
  for (let index = 0; index < steps.length; index++) {
    if (index !== 0) {
      stepContent.push(
        <CurveContainer key={`${index}_curve`}>
          <Row>
            <CurveRowItem index={index}
                          className={cn(index % 2 !== 0 ? 'curve-path-container-or' : 'curve-path-container-tr')}>
              <Canvas width={1276}/>
            </CurveRowItem>
          </Row>
        </CurveContainer>
      );
    }
    stepContent.push(
      <ServiceStep
        key={`${index}_step`}
        image={steps[index].image.publicURL}
        title={steps[index].title}
        subtitle={steps[index].subtitle}
        action={steps[index].href}
        right={index % 2 === 0}
      />
    );
  }

  return (stepContent);
};

ServiceSteps.propTypes = {
  steps: PropTypes.array.isRequired
};

export default ServiceSteps;