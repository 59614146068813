import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import Layout from '../components/Layout';
import Img from "gatsby-image";
import ServiceSteps from "../components/services/ServiceStep";
import '../components/styles/services.sass';
import Title from "../components/title/Title";
import GenericHeader from "../components/header_section/GenericHeader";
import Container from "../components/layouts/Container";
import Row from "../components/layouts/Row";
import FooterDivider from "../components/FooterDivider";
import styled from "styled-components";
import {media} from "../components/styles/MediaMixins";

const Subtitle = styled.div`
    color: #33333366;
    font-size: ${24 / 16}rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: ${42 / 16}rem;
`

const HeaderTitle = styled.h2`
    color: #535B6C;
    font-size: ${34 / 16}rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: ${45 / 16}rem;
    margin-bottom: ${15 / 16}rem;
    ${media.touch`
        padding-top: ${25 / 16}rem;
        padding-bottom: ${10 / 16}rem;
    `};
`

const ServicesContainer = styled.div`
    min-height: ${739 / 16}rem;
    display: flex;
    padding-right: ${50 / 16}rem;
    padding-left: ${50 / 16}rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    ${media.touch`
        padding-top: ${30 / 16}rem;
    `};
`

const ServiceRow = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: flex-start;
    width: 100%;
    max-width: 1570px;
    margin: 0 auto;
    ${media.touch`
        flex-direction: column;
    `};
    ${media.desktop`
        padding-top: ${149 / 16}rem;
    `};
`

const TextItem = styled.div`
    width: 30%;
    ${media.touch`
        flex-direction: column;
        width: 100%;
    `};
    ${media.desktop`
        max-width: ${512 / 16}rem;
        width: 40%;
    `};
    ${media.widescreen_only`
        margin-left: 50px;
    `};
`

const ImageItem = styled.div`
    ${media.touch`
        flex-direction: column;
        max-width: ${448 / 16}rem;
        width: 80%;
    `};
    ${media.desktop`
        max-width: ${470 / 16}rem;
        margin-right: ${60 / 16}rem;
        width: 35%;
    `};
    ${media.widescreen_only`
        margin-right: 50px;
    `};
`

const SecondTitle = styled(Title)`
    ${media.touch`
        margin-top: ${40 / 16}rem;
        margin-bottom: ${46 / 16}rem;
    `};
    ${media.desktop`
        margin-top: ${50 / 16}rem;
        margin-bottom: ${40 / 16}rem;
    `};
`

const Divider = styled.hr`
    width: 82%;
    max-width: 1570px;
    padding-left: 50px;
    padding-right: 50px;
    margin-left: auto;
    margin-right: auto;
`

const ServicesOverviewPage = ({data}) => {

  const {markdownRemark: post} = data;

  return (
    <Layout
      meta_image={post.frontmatter.title_image.childImageSharp.fluid.src}
      meta_image_width={post.frontmatter.title_image.childImageSharp.fluid.presentationWidth}
      meta_image_height={post.frontmatter.title_image.childImageSharp.fluid.presentationHeight}
      local_title={`${post.frontmatter.title} - Cypress`}
      serviceIsActive={true}
      meta_description={post.frontmatter.meta_description}
      meta_keyword={post.frontmatter.meta_keyword}>
      <GenericHeader title={post.frontmatter.title}
                     subtitle={post.frontmatter.subtitle}
                     no_right_padding={post.frontmatter.no_right_padding}
                     title_image={post.frontmatter.title_image}
                     title_image_mobile={post.frontmatter.title_image_mobile}/>
      <ServicesContainer>
        <ServiceRow justifyContent={'space-around'}>
          <ImageItem>
            <Img alt={`Service ${post.frontmatter.title}`} imgStyle={{height: 'auto'}}
                 fluid={post.frontmatter.image_description.childImageSharp.fluid}/>
          </ImageItem>
          <TextItem maxWidth={636}>
            {post.frontmatter.header_description.map((desc, index) => (
              <React.Fragment key={index}>
                <HeaderTitle>{desc.title}</HeaderTitle>
                {desc.subtitle.map((item, index) => (
                  <Subtitle key={index}>{item}</Subtitle>
                ))}
                <br/>
                <br/>
                <br/>
              </React.Fragment>
            ))}
          </TextItem>
        </ServiceRow>

      </ServicesContainer>
      <br/>
      <Divider/>
      <Container>
        <Row>
          <SecondTitle>
            {post.frontmatter.steps_title}
          </SecondTitle>
        </Row>
      </Container>
      <br/>
      <ServiceSteps steps={post.frontmatter.steps}/>
      <br/>
      <br/>
      <FooterDivider/>
      <br/>
      <br/>
    </Layout>
  )
};

ServicesOverviewPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ServicesOverviewPage

export const servicesConsultingPageQuery = graphql`
  query ServicesOverviewPage($id: String!, $maxHeaderImg: Int!) {
    markdownRemark(id: {eq: $id}) {
      frontmatter {
        title
        meta_description
        meta_keyword
        subtitle
        no_right_padding
        image_description {
          childImageSharp {
            fluid(quality: 100, maxWidth: 470, srcSetBreakpoints: [470]) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        title_image {
          childImageSharp {
            fluid(quality: 100, maxWidth: $maxHeaderImg, srcSetBreakpoints: [749, $maxHeaderImg], sizes: "(max-width: 1366px) calc(749px), (min-width: 1367px) calc(916px), 100vw") {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        title_image_mobile {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1024, srcSetBreakpoints: [1024]) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        steps_title
        header_description {
          title
          subtitle
        }
        steps {
          title
          subtitle
          image {
            publicURL
          }
        }
      }
    }
  }
`;
