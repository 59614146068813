import styled from "styled-components";
import {media} from '../styles/MediaMixins';
import PropTypes from "prop-types";
import RowItem from "./RowItem";

const OrderedRowItem = styled(RowItem)`
    ${media.touch`order: ${props => props.smOrder || 'unset'}`};
    ${media.desktop`order: ${props => props.lgOrder || 'unset'}`};    
    text-align: ${props => props.textAlign || 'unset'};
`
OrderedRowItem.propTypes = {
    smOrder: PropTypes.number,
    lgOrder: PropTypes.number,
    className: PropTypes.string,
    textAlign: PropTypes.oneOf(['left', 'right']),
    maxWidth: PropTypes.number,
    shortMaxWidth: PropTypes.number,
    breakpoint: PropTypes.oneOf(['desktop'])

}
export default OrderedRowItem